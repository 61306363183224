.pageHome {
  #homeStretchedBackground {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: 100%; 
    min-height: 100%;
    z-index: -1;
    background-image: url('/images/home-img2.jpg');
    background-attachment: fixed;
    background-clip: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}