.pageClipping {
  .carousel-control {
    width: 40px;
    &.left {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.0001) 100%
      );
    }
    &.right {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.0001) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
    .glyphicon:before {
      color: black;
    }
  }

  .col-clipping {
    float: none;
    position: relative;
    min-height: 1px;

    @media (min-width: $screen-sm-min) {
      float: left;
      width: 20% !important;
    }

    .img-responsive {
      width: 100%;
    }

    p {
      margin-top: 2rem;
      line-height: 2.5rem;
      font-size: 2rem;
    }
  }
}
