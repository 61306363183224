$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import './Variables';
@import "./theme";
@import "./responsive";
@import './Common';
@import './Home';
@import './Perfil';
@import './Portfolio';
@import './Clipping';
@import './Contato';

