@import url(https://fonts.googleapis.com/css?family=Lato|Open+Sans+Condensed:300|Open%20Sans:300,400,500,600,700);

/* ------------------------------------------------------------------------------
	 Typography
-------------------------------------------------------------------------------*/

p {
  font-size: 14px;
  line-height: 22.4px;
  color: #6c7279;
}

h1 {
  font-size: 65px;
  color: #2d3033;
}

h2 {
  font-size: 40px;
  color: #2d3033;
}

h3 {
  font-size: 28px;
  color: #2d3033;
  font-weight: 300;
}

h4 {
  font-size: 22px;
  color: #2d3033;
  font-weight: 400;
}

h5 {
  font-size: 14px;
  color: #2d3033;
  text-transform: uppercase;
  font-weight: 700;
}

a {
  color: #e84545;
  &:hover, &:focus {
    text-decoration: none;
    -moz-transition: background-color, color, 0.3s;
    -o-transition: background-color, color, 0.3s;
    -webkit-transition: background-color, color, 0.3s;
    transition: background-color, color, 0.3s;
  }
}

body {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 400;
  color: #6c7279;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

.no-padding {
  padding: 0;
}

.no-gutter [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

/* ------------------------------------------------------------------------------
	 Header & navigation
-------------------------------------------------------------------------------*/

#header {
  font-family: "Lato", sans-serif;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  margin: 0;
  padding-left: 3%;
  padding-right: 3%;
  z-index: 500;
  .header-content {
    margin: 0 auto;
    padding: 22px 0;
    width: 100%;
    -moz-transition: padding 0.3s;
    -o-transition: padding 0.3s;
    -webkit-transition: padding 0.3s;
    transition: padding 0.3s;
    .navigation {
      padding-top: 16px;
    }
  }
  .logo {
    font-size: 21px;
    color: #000;
    font-weight: 700;
    float: left;
    text-transform: uppercase;
    img.logoImage {
      width: 300px !important;
      height: 66px !important;
    }
  }
  &.fixed {
    background-color: #fff;
    .header-content {
      border-bottom: 0;
      padding: 25px 0;
    }
    .nav-toggle {
      top: 18px;
    }
  }
}

.navigation {
  float: right;
  &.open {
    opacity: 0.9;
    visibility: visible;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }
  li {
    display: inline-block;
    a {
      color: #000;
      font-size: 16px;
      line-height: 34px;
      font-weight: 300;
      margin-left: 40px;
      letter-spacing: 2px;
      text-transform: uppercase;
      &:hover, &.active {
        color: #BC9C84;
      }
  
      &.lnkInstagram {
        margin-left: 10px;
      }
    }
  }
  .linkTxt {
    display: none;
  }
  .linkImg {
    display: none;

    @media screen and (min-width: 1025px) {
      display: inline-block;     
    }
  }
}

.nav-toggle {
  display: none;
  height: 44px;
  overflow: hidden;
  position: fixed;
  right: 5%;
  text-indent: 100%;
  top: 23px;
  white-space: nowrap;
  width: 44px;
  z-index: 99999;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &:before, &:after {
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
  }
  &:before {
    background-color: #626367;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  &:after {
    background-color: #626367;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    -moz-transition-duration: 0s;
    -o-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
  }
  span {
    background-color: #fff;
    bottom: auto;
    display: inline-block;
    height: 3px;
    left: 50%;
    position: absolute;
    right: auto;
    top: 50%;
    width: 18px;
    z-index: 10;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    &:before, &:after {
      background-color: #fff;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-transition: -moz-transform 0.3s;
      -o-transition: -o-transform 0.3s;
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
    }
    &:before {
      -moz-transform: translateY(-6px) rotate(0deg);
      -ms-transform: translateY(-6px) rotate(0deg);
      -webkit-transform: translateY(-6px) rotate(0deg);
      transform: translateY(-6px) rotate(0deg);
    }
    &:after {
      -moz-transform: translateY(6px) rotate(0deg);
      -ms-transform: translateY(6px) rotate(0deg);
      -webkit-transform: translateY(6px) rotate(0deg);
      transform: translateY(6px) rotate(0deg);
    }
  }
  &.close-nav {
    &:before {
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    &:after {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    span {
      background-color: rgba(255, 255, 255, 0);
      &:before, &:after {
        background-color: #fff;
      }
      &:before {
        -moz-transform: translateY(0) rotate(45deg);
        -ms-transform: translateY(0) rotate(45deg);
        -webkit-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
      }
      &:after {
        -moz-transform: translateY(0) rotate(-45deg);
        -ms-transform: translateY(0) rotate(-45deg);
        -webkit-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
}

.section {
  &.section-content {
    position: fixed;
    top: 110px;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 3%;
    overflow: auto;
    padding-top: 25px;
  }
}

.carousel-indicators {
  bottom: -50px;
}
.carousel-indicators li {
  width: 15px;
  height: 15px;
  border: 1px solid #444;
  background-color: #000 \9;
  background-color: transparent; 
}
.carousel-indicators .active {
  margin: 1px;
  width: 15px;
  height: 15px;
  border: 1px solid #444;
  background-color: #444;
}

/* ------------------------------------------------------------------------------
	 Perfil
-------------------------------------------------------------------------------*/

/* ------------------------------------------------------------------------------
	 Footer
-------------------------------------------------------------------------------*/

.footer {
  width: 94%;
  margin: auto;
  text-align: left;
  padding-top: 5%;
  padding-bottom: 7%;
  h4 {
    font-size: 13px;
    color: #000;
    margin: 0 0 15px 0;
    padding: 0px;
  }
  p {
    font-size: 11px;
    color: #6c7279;
  }
  a {
    color: #000;
    &:hover {
      text-decoration: underline;
    }
  }
  .footer-share {
    margin-top: 0;
    li {
      display: inline-block;
      float: none;
    }
    a {
      border: none;
      font-size: 13px;
      color: #000;
      letter-spacing: 15px;
    }
  }
  .fa-heart {
    color: #e84545;
    font-size: 11px;
    margin: 0 2px;
  }
}

#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #D0D0D2;
  line-height: 2rem;
  font-size: 14px;
  .footerText {
    padding: 20px;

    .footerSpanLeft {
      padding-right: 30px;
    }

    .footerSpanRight {
      padding-left: 30px;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}