.pageContato {
  .section-content {
    top: 135px;
    background-color: #e3e3e3;
  }

  .conteudo-contato {
    background-color: #fff;
    margin: 85px auto 150px;
    max-width: 896px;
    padding: 0;

    @media screen and (min-width: 1366px) {
      margin-top: 81px;
      max-width: 993px;
    }
    @media screen and (min-width: 1920px) {
      margin-top: 112px;
      max-width: 1280px;
    }
  }

  .detalhes-contato {
    margin: 30px;
    @media screen and (max-width: $screen-xs-max) {
      div.col-xs-12 {
        text-align: center !important;
      }

      .visible-xs {
        display: inline-block !important;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .formulario-contato {
    margin: 0;
    background-color: #faf5f1;
    color: #c3b1a3;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;

    .form-column:first-child .form-group:first-child {
      margin-top: 15px;
    }
    
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px;
      margin: 30px 30px 45px;

      .form-group:first-child {
        margin-top: 15px;
      }
    }

    .mensagemEnviada {
      font-weight: bold;
      font-size: 1.8rem;
      padding: 80px;
    }

    .form-group {
      .form-control {
        outline: none;
        box-shadow: none;
        background-color: #eadfd9;
        color: #4f3d31;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.5rem;
        height: 60px;
        padding: 15px;
        border-radius: 2px;
        border: 0;
        border-top: 2px solid #d8cec5;
        &::placeholder {
          color: #c3b1a3;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &.has-error .form-control {
        border: 1px solid #a94442;
        border-top-width: 2px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      }

      .form-control-feedback {
        top: 13px;
      }
    }

    #message {
      height: 136px;
      resize: none;
    }

    #sendBtn {
      background-color: #ceb7a7;
      border: 0;
      border-bottom: 2px solid #b89d88;
      outline: none;
      margin-top: 0;
      width: 100%;
      height: 60px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.5rem;
    }
  }
}
