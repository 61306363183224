.pagePortfolio {
  #header {
    z-index: -1;

    &.open {
      z-index: 1200;
    }
  }

  .photoThumbnail {
    float: left;
    position: relative;
    min-height: 1px;
    padding: 2px;
    width: 33.333333%;

    @media (min-width: $screen-sm-min) {
      width: 20%;
    }

    @media (min-width: $screen-md-min) {
      width: 11.111111%;
    }

    a > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .galleryRow {
    position: relative;
    margin-bottom: 60px;

    @media screen and (min-width: 1200px) {
      min-height: 419px;
    }

    @media screen and (min-width: 1366px) {
      min-height: 476px;
    }

    @media screen and (min-width: 1600px) {
      min-height: 556px;
    }

    @media screen and (min-width: 1800px) {
      min-height: 625px;
    }

    @media screen and (min-width: 1920px) {
      min-height: 643px;
    }

    @media screen and (min-width: 2560px) {
      min-height: 881px;
    }
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 600;

    @media (min-width: $screen-lg-min) {
      position: absolute;
    }
  }

  .photoModal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    overflow: hidden;

    @media (min-width: $screen-lg-min) {
      position: absolute;
    }

    .photoContainer {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;

      .prevButton,
      .nextButton {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        width: 60px;
        height: 120px;
        color: #fff;
        font-size: 40px;
        line-height: 120px;
        z-index: 2000;
      }

      .prevButton {
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
        left: 0;
      }

      .nextButton {
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
        right: 0;
      }

      .photo {
        position: relative;
        height: 100%;
        width: auto;
        display: inline-block;
        padding: 10px;
        z-index: 1500;

        .img-responsive {
          position: relative;
          height: 100%;
          width: auto;
          margin: auto;

          @media screen and (max-width: $screen-md-max) and (orientation: portrait) {
            width: 100%;
            height: auto;
          }
        }

        @media screen and (max-width: $screen-md-max) and (orientation: portrait) {
          position: absolute;
          left: 50%;
          top: 50%;
          height: auto;
          width: 100%;
          transform: translate(-50%, -50%);

          &.portrait {
            height: 75%;

            .img-responsive {
              height: 100%;
              width: auto;
            }
          }
        }
      }
    }

    .closeButton {
      position: absolute;
      display: block;
      width: 25px;
      height: 25px;
      top: 20px;
      right: 20px;
      color: white;
      text-align: center;
      vertical-align: middle;
      line-height: 25px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);

      span {
        display: inline-block;
      }
    }
  }
}
