@media screen and (max-width: 1024px) {
  #header {
    .logo {
      img.logoImage {
        width: 200px !important;
        height: 44px !important;
      }
    }
  }
  .section{
    &.section-content {
      top: 90px;
    }
  }
  .nav-toggle {
    display: block;
  }
  .navigation {
    position: fixed;
    background-color: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 700;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
    transition: opacity 0.5s, visibility 0s 0.5s;
    .primary-nav {
      position: relative;
      top: 45%;
      -moz-transform: translateY(-45%);
      -ms-transform: translateY(-45%);
      -webkit-transform: translateY(-45%);
      transform: translateY(-45%);
    }
    li {
      display: block;
      margin-bottom: 20px;
      a {
        display: block;
        font-size: 25px;
        margin: 0;
        color: #fff;
        font-size: 13px;
        text-align: center;

        &.lnkInstagram {
          margin-left: 0;
        }
      }
    }
    .linkTxt {
      display: inline-block;
    }
    .linkImg {
      display: none;
    }
  }
  .flex-control-nav {
    bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .banner-text {
    padding-top: 30%;
    h1 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .flexslider {
    padding-bottom: 80px;
  }
  .flex-control-nav {
    width: 100%;
  }
  .footer .footer-col {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: $screen-xs-max) {
  #footer {
    .footerSpanLeft, .footerSpanRight {
      width: 100%;
      text-align: center;
      padding: 0 !important;
    }
  }
}