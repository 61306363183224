.pagePerfil {
  .section-content {
    top: 135px;
    background: #e3e3e3;
  }

  .profilePhoto {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    width: 100%;

    .img-responsive {
      width: 100%;
      height: auto;
    }
  }

  .profileText {
    position: relative;
    min-height: 1px;
    float: left;
    width: 100%;

    p {
      margin-top: 3rem;
      font-size: 1.8rem;
      line-height: 2.2rem;
      @media (min-width: 1600px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }

    .quotes {
      font-size: 3rem;
      font-weight: bold;
    }
  }

  @media (min-width: $screen-sm-min) {
    .profilePhoto {
      width: 30%;
    }

    .profileText {
      width: 30%;
      margin-left: 5%;
    }
  }
}
